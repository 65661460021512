import { Badge, Box, Card, CardActionArea, Chip, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";

const BandTileNotif = ({ name, pfpUrl, genres, memberCount, _id, notifId, setData, type, uniEd }) => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));

    const acceptInvite = e => {
        e.stopPropagation()
        setData((prev) => {
            let arr = [...prev];
            //console.log("ARR", arr[arr.indexOf(notifId)]);
            arr.splice(arr.findIndex((ent) => ent._id === _id), 1);
            //console.log('PREV', arr);
            return arr;
        });
        if (type === 'Band' && !name.includes('кандидатства')) {
            axios({
                method: 'post',
                url: 'https://grandstaff.herokuapp.com/api/notifications/acceptBandAdd/',
                data: {
                    notifId: notifId
                }
            }).then(res => {
                //
            }).catch(err => {
                //console.log(err);
            });
        }
        else {
            if (type === 'Band') {
                axios({
                    method: 'put',
                    url: 'https://grandstaff.herokuapp.com/api/transaction/transactBand/' + uniEd + '/' + _id,
                    headers: { 'Authorization': 'Bearer ' + (user ? user.token : '0') },
                }).then(res => {
                    //
                }).catch(err => {
                    //console.log(err);
                })
            } else {
                axios({
                    method: 'put',
                    url: 'https://grandstaff.herokuapp.com/api/transaction/transactUser/' + uniEd + '/' + _id,
                    headers: { 'Authorization': 'Bearer ' + (user ? user.token : '0') },
                }).then(res => {
                    //
                }).catch(err => {
                    //console.log(err);
                })
            }
        }
    };

    const declineInvite = e => {
        e.stopPropagation()
        setData((prev) => {
            let arr = [...prev];
            arr.splice(arr.findIndex((ent) => ent._id === _id), 1);
            //console.log('PREV', arr);
            return arr;
        });
        if (type === 'Band') {
            axios({
                method: 'post',
                url: 'https://grandstaff.herokuapp.com/api/notifications/rejectBandAdd/',
                data: {
                    notifId: notifId
                }
            }).then(res => {
                //
            }).catch(err => {
                //console.log(err);
            });
        } else {
            axios({
                method: 'delete',
                url: 'https://grandstaff.herokuapp.com/api/transaction/deleteTransact/' + uniEd + '/' + _id,
            }).then(res => {
                //
            }).catch(err => {
                //console.log(err);
            })
        }
    };

    const clickNavigate = () => {
        if (type === 'Band') navigate('/band/' + _id)
        else navigate('/profile/' + _id);
    }

    return (
        <Card style={{ backgroundColor: "#00000000" }} elevation="0" >
            <CardActionArea onClick={clickNavigate}>
            <Box sx={{ width: {lg: '400px', xs: "100%"}, display: 'flex', padding: '10px 7.5px', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }}>
                    <Box style={{
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${pfpUrl})`,
                        borderRadius: '20px',
                        height: '85px',
                        width: '85px',
                        marginRight: '12px',
                        flexShrink: 0
                    }} >
                        <Badge badgeContent={memberCount} color="primary"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}>
                            <Box style={{ width: '70px', height: '70px' }} />
                        </Badge>
                    </Box>
                    <Box>
                        {
                            type === 'Band' ?
                            <Typography gutterBottom variant="p" component="div" fontSize="18px">
                                {name}
                            </Typography>
                            :
                            <Typography gutterBottom variant="p" component="div" fontSize="18px">
                                <b>{name.split('кандидатства')[0]}</b> кандидатства{name.split('кандидатства')[1].substring(0, name.split('кандидатства')[1].indexOf('"'))}<b>{name.split('обявата')[1]}</b>
                            </Typography>
                        }
                        <Stack direction='row' gap={'2px'} flexWrap="wrap">
                            {genres ? genres.map(genre => {
                                return (<Chip label={genre} />)
                            }) : ""}
                        </Stack>
                    </Box>
                    {
                        <Stack flexShrink="0" marginLeft="auto" width="50px" direction="column" justifyContent="center" alignItems="center">
                            <IconButton onClick={acceptInvite}>
                                <CheckIcon />
                            </IconButton>
                            <IconButton onClick={declineInvite}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    }
                </Box>
            </CardActionArea>
        </Card>
    );
}

export default BandTileNotif;