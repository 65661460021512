import { alpha, Box, Button, Card, Divider, Stack, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import ScheduleCalendar from "./ScheduleCalendar";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useContext, useState } from "react";
import InfoStack from "./InfoStack";
import HistoryStack from "./HistoryStack";
import SchoolIcon from '@mui/icons-material/School';
import EditInfoPopup from "./EditInfoPopup";
import { EntityContext } from "../context/EntityContext";
import { FacebookShareButton } from "react-share";
import FacebookIcon from '@mui/icons-material/Facebook';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ width: '100%', height: { xl: 'calc(100% - 430px)', lg: 'calc(100% - 330px)' } }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const InfoPanel = () => {
    const { desc, gsm, contactEmail, uniEd, editView, type, _id } = useContext(EntityContext);
    let isBand = type === "Band";
    const [value, setValue] = useState(0);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Card sx={{
            position: 'relative',
            borderRadius: '10px',
            backgroundColor: 'background.paper',
            height: { xl: '95%', lg: '100%', xs:"700px" }, width: isMobile ? "calc(100vw - 64px)" : "350px", backdropFilter: 'blur(10px)',
            backgroundColor: theme => alpha(theme.palette.background.paper, 0.57)
        }}>
            <Box sx={{ overflowY: 'auto', padding: '15px', height: { xl: '300px', lg: '200px', xs: '200px' }, lineHeight: 'normal' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <b>Информация: </b>
                    {editView ?
                        <EditInfoPopup /> //REWIRED
                        :
                        null
                    }
                    <FacebookShareButton url={`https://www.grandstaff.online/profile/${_id}`}>
                        <Button size="small" sx={{color: "#3482E2", marginLeft: "10px"}} endIcon={<FacebookIcon/>}>
                            сподели
                        </Button>
                    </FacebookShareButton>
                </div>
                {
                    isBand ? '' : uniEd !== 'Undefined' ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', opacity: '0.7' }}>
                        <SchoolIcon fontSize="small" />
                        <Box width="5px" />
                        <Typography variant="p" component="p" marginTop='5px'>{uniEd}</Typography>
                    </div> : null
                }
                
                <Typography variant="p" component="p" fontSize="16px" marginTop='5px'>{desc}</Typography>
            </Box>
            <Divider />
            <Stack direction={'column'} justifyContent='center' alignItems="center" height="80px">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <PhoneIcon />
                    <Box width="5px" />
                    <Typography variant="h5" component="div"><b>{gsm}</b></Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <EmailIcon fontSize="16px" />
                    <Box width="5px" />
                    <Typography variant="p" component="div" fontSize="16px">  {contactEmail}</Typography>
                </div>
            </Stack>
            <Divider />
            <Stack direction="row" justifyContent='center'>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="График" />
                    <Tab label={isBand ? "Членове" : "Групи"} />
                    <Tab label="История" />
                </Tabs>
            </Stack>
            <TabPanel value={value} index={0}>
                <ScheduleCalendar />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <InfoStack />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <HistoryStack />
            </TabPanel>
        </Card>
    );
}

export default InfoPanel;