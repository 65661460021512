import { Box, Stack, Typography, alpha } from "@mui/material";
import MemberTile from "../MemberTile";
import ChatPreview from "./ChatPreview";
import useFetch from "../../hooks/useFetch";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import openSocket from 'socket.io-client';
import { useParams } from "react-router";

const ChatSideView = () => {
    //const { data, loading, error } = useFetch('https://grandstaff.herokuapp.com/api/messages/getRecipients');
    const loggedUser = JSON.parse(localStorage.getItem('user'));

    let { chatUid } = useParams();

    let [ chatPrevs, setPrevs ] = useState([ //Fetching from back end
    ]);
    // if (!loading) chatPrevs = data.recipients;

    function loadPrevs() {
        axios({
            method: 'get',
            url: 'https://grandstaff.herokuapp.com/api/messages/getRecipients',
            headers: {'Authorization': 'Bearer ' + (loggedUser != null ? loggedUser.token : '0')}
        }).then(res => {
            setPrevs(res.data.recipients);
        }).catch(err => {

        });
    }

    useEffect(() => {
        const socket = openSocket('https://grandstaff.herokuapp.com');
        socket.on('message', socketData => {
            if (socketData.userId == loggedUser.userId || socketData.userId2 == loggedUser.userId) {
                loadPrevs();
            }
            else {}
        });
        loadPrevs();
    }, []);

    return (
        <Box sx={{ overflowY: 'auto', height: '100%', width: {lg: "350px", xs: "calc(100vw - 60px)"}, backgroundColor: "background.paper", borderLeft: {lg: '1px solid rgba(255, 255, 255, 0.12)', xs:""}, backdropFilter: 'blur(10px)', backgroundColor: theme => alpha(theme.palette.background.paper, 0.57) }}>
            <Stack direction="column">
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    height="65px" style={{ borderBottom: '2px solid rgba(255, 255, 255, 0.2)' }}>
                    <Typography variant="p" component="div" fontSize="20px">
                        <b>Съобщения</b>
                    </Typography>
                </Stack>
                {
                    chatPrevs[0] ?
                    chatPrevs.map(cp => <ChatPreview {...cp}/>)
                    :
                    ''
                }
            </Stack>
        </Box>
    );
}

export default ChatSideView;