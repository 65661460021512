import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Box, Tabs, Tab, Typography, alpha } from '@mui/material';
import { Stack } from '@mui/system';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useParams } from 'react-router';
import axios from 'axios';
import { EntityContext } from '../context/EntityContext';
import { useNavigate } from 'react-router';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ width: '100%', height: { xl: 'calc(100% - 430px)', lg: 'calc(100% - 330px)' } }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const EditBand = () => {
    const {name, type, firstName, setEntityState} = React.useContext(EntityContext);
    const [open, setOpen] = React.useState(false);
    const [bandName, setBandName] = React.useState(type==='Band' ? name : firstName);
    const { _id } = useParams();
    const navigate = useNavigate();
    const [pass, setPass] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const [value, setValue] = React.useState(0);
    const [error, setError] = React.useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleUnopen = () => {
        setOpen(false);
    }

    const handleClose = () => {
        if (type === "Employer") {
            axios({
                method: 'patch',
                url: 'https://grandstaff.herokuapp.com/api/define',
                headers: {'Authorization': 'Bearer ' + (user != null ? user.token : '0')},
                data: {
                    firstName: bandName,
                    jobId: _id
                }
            }).then(res => {
                setEntityState(prev => ({
                    ...prev,
                    firstName: bandName
                }));
            }).catch(err => {
                setError(err.response.data.message);
            });
            setOpen(false);
        }
        else {
            axios({
                method: 'patch',
                url: 'https://grandstaff.herokuapp.com/api/band/editBand',
                headers: {'Authorization': 'Bearer ' + (user != null ? user.token : '0')},
                data: {
                    name: bandName,
                    bandId: _id
                }
            }).then(res => {
                setEntityState(prev => ({
                    ...prev,
                    name: bandName
                }));
            }).catch(err => {
                setError(err.response.data.message);
            });
            setOpen(false);
        }

    };

    const handleDelete = () => {
        if (type === "Employer") {
            axios({
                method: 'patch',
                url: 'https://grandstaff.herokuapp.com/api/deleteJob/' + _id,
                headers: {'Authorization': 'Bearer ' + (user != null ? user.token : '0')},
                data: {
                    password: pass
                }
            }).then(res => {
                //console.log('IN THEN');
                navigate('/job');
            }).catch(err => {
                setError(err.response.data.message);
                setOpen(true);
            });
            setOpen(false);
        }
        else {
            axios({
                method: 'patch',
                url: 'https://grandstaff.herokuapp.com/api/band/deleteBand/' + _id,
                headers: {'Authorization': 'Bearer ' + (user != null ? user.token : '0')},
                data: {
                    password: pass
                }
            }).then(res => {
                navigate('/profile');
            }).catch(err => {
                setError(err.response.data.message);
                setOpen(true);
            });
            setOpen(false);
        }

    };

    const handleLeave = () => {
        axios({
            method: 'patch',
            url: 'https://grandstaff.herokuapp.com/api/band/removeMember',
            headers: {'Authorization': 'Bearer ' + (user != null ? user.token : '0')},
            data: {
                password: pass,
                userId: user.userId,
                bandId: _id
            }
        }).then(res => {
            navigate('/profile');
        }).catch(err => {
            setError(err.response.data.message);
        });
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <IconButton style={{ marginBottom: '5px' }} onClick={handleClickOpen}>
                <SettingsIcon />
            </IconButton>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleUnopen}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    sx: {
                        backdropFilter: 'blur(10px)',
                        backgroundColor: theme => alpha(theme.palette.background.paper, 0.67),
                        borderRadius: "16px"
                    },
                }}
            >
                <DialogTitle sx={{ display: 'flex' }}>
                    {"Редакция на данни"}
                </DialogTitle>
                <Stack direction="row" justifyContent='center' sx={{ marginTop: '-15px' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                            <Tab label="Име" />
                            <Tab label="Изтриване" />
                            { type === 'Band' ? <Tab label="Напускане" /> : ""}
                        </Tabs>
                    </Stack>
                <TabPanel value={value} index={0} >
                    <DialogContent sx={{ padding: '15px', width: {lg: '400px', xs: 'calc(100vw - 64px)'} }}>
                        <Stack direction={'column'} spacing="15px">
                            <Stack spacing={'15px'} direction='row'>
                                <TextField
                                    style={{ width: '100%' }}
                                    id="band-name"
                                    label={type==="Employer" ? "Заглавие" : "Име на банда"}
                                    defaultValue={bandName}
                                    onChange={e => {
                                        setBandName(e.target.value);
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <Typography color={"red"}>
                                { error }
                            </Typography>
                    <DialogActions>
                        <Button onClick={handleClose}>Избери</Button>
                    </DialogActions>
                </TabPanel>
                <TabPanel value={value} index={1} >
                    <DialogContent sx={{ padding: '15px', width: {lg: '400px', xs: 'calc(100vw - 64px)'} }}>
                        <Stack direction={'column'} spacing="15px">
                            <Stack spacing={'15px'} direction='row'>
                                <FormControl variant="outlined" sx={{ width: '500px' }}>
                                    <InputLabel htmlFor="password"  >Сегашна парола</InputLabel>
                                    <OutlinedInput
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={e => {
                                            setPass(e.target.value);
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Парола"
                                    />
                                </FormControl>
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <Typography color={"red"}>
                                { error }
                            </Typography>
                    <DialogActions>
                        <Button onClick={handleDelete} style={{ color: "red" }}>Изтрий</Button>
                    </DialogActions>
                </TabPanel>
                <TabPanel value={value} index={2} >
                    <DialogContent sx={{ padding: '15px', width: {lg: '400px', xs: 'calc(100vw - 64px)'} }}>
                        <Stack direction={'column'} spacing="15px">
                            <Stack spacing={'15px'} direction='row'>
                                <FormControl variant="outlined" sx={{ width: '500px' }}>
                                    <InputLabel htmlFor="password"  >Сегашна парола</InputLabel>
                                    <OutlinedInput
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={e => {
                                            setPass(e.target.value);
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Парола"
                                    />
                                </FormControl>
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <Typography color={"red"}>
                                { error }
                            </Typography>
                    <DialogActions>
                        <Button onClick={handleLeave} style={{ color: "red" }}>Напусни</Button>
                    </DialogActions>
                </TabPanel>
            </Dialog>
        </div>
    );
}

export default EditBand;