import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useContext } from "react";
import InfoPanel from "../components/InfoPanel";
import JobInfoPanel from "../components/JobInfoPanel";
import PreviewCarousel from "../components/PreviewCarousel";
import UserDetails from "../components/UserDetails";
import { EntityContext } from "../context/EntityContext";
import EditPreviewCarousel from "./EditPreviewCarousel";
import MobileInfoPanelPopUp from "./MobileInfoPanelPopUp";
import openSocket from 'socket.io-client';
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";

const Entity = () => {
    const user = useContext(EntityContext);
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [ userBackground, setUserBackground ] = useState('');

    useEffect(() => {
        setUserBackground(user.background);
        const socket = openSocket('https://grandstaff.herokuapp.com');
        socket.on('instrChanged', socketData => {
            //console.log("CHATUID", user._id);
            if (user._id) {
                if (socketData.userIdC === user._id) {
                    setUserBackground(socketData.instr[0]);
                }
            }
        });
    }, []);

    return (
        <Box sx={{ height: '100%', scrollSnapAlign: 'start', backgroundSize: 'cover', backgroundPosition: 'right bottom', backgroundImage: `url('https://grandstaff.herokuapp.com/images/inst/${userBackground}.png')` }}>
            <Box sx={{ padding: { lg: "15px", xl: "50px 75px 0 75px", md: "50px 75px 0 75px" }, height: '100%', backgroundSize: "100% 100%", backgroundImage: `url(${isDark ? "https://grandstaff.herokuapp.com/images/DarkFade.png" : "https://grandstaff.herokuapp.com/images/LightFade.png"})` }}>
                {
                    user.type === 'Employer'
                        ?
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: 'auto', alignItems: 'center' }}>
                            <UserDetails/>
                            <Box sx={{ height: '1px', margin: { xl: '35px 45px 45px 45px', lg: '5px 15px 15px 15px', xs: '5px 15px 15px 15px' }, width: "75%", backgroundColor: 'primary.main' }} />
                            <JobInfoPanel/>
                        </div>
                        :
                        <div style={{ display: 'flex', height: '100%', width: 'auto', flexDirection: isMobile ? "column" : "row"}}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, minWidth: 0 }}>
                                <UserDetails/>
                                <Box sx={{ height: '1px', margin: { xl: '35px 45px 45px 45px', lg: '5px 15px 15px 15px',  xs: '5px 15px 15px 15px' } , backgroundColor: 'primary.main' }} />
                                <Box sx={{ maxWidth: { xl: '870px', lg: '570px' } }}> {/* If you change preview size, adjust this*/}
                                    {
                                        user.editView ?
                                        <EditPreviewCarousel/> :
                                        <PreviewCarousel/>
                                    }
                                </Box>
                            </div>
                            {
                                isMobile ?
                                <MobileInfoPanelPopUp>
                                    <InfoPanel/>
                                </MobileInfoPanelPopUp>
                                :
                                <InfoPanel/>
                            }
                        </div>
                }
            </Box>
        </Box>
    );
}

export default Entity;