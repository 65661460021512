import { useTheme } from "@emotion/react";
import { Box, Stack, TextField, Typography, alpha } from "@mui/material";
import { useParams } from "react-router";
import Message from "./Message";
import { useRef, useState } from "react";
import SendIcon from '@mui/icons-material/Send';
import useFetch from "../../hooks/useFetch";
import axios from "axios";
import { useEffect } from "react";
import openSocket from 'socket.io-client';

const ChatArea = ({ user }) => {
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    const loggedUser = JSON.parse(localStorage.getItem('user'));

    const stackRef = useRef(null);

    const [chatInput, setChatInput] = useState('');

    let { chatUid } = useParams();

    let [ username, setUsername ] = useState(""); //fetch from back end using chatUid
    let [ pfpUrl, setPfpUrl ] = useState(""); //fetch from back end using chatUid
    let [ messages, setMessages ] = useState([ //fetch from back end using chatUid
    ]);

    // const { data, loading, error } = useFetch('https://grandstaff.herokuapp.com/api/messages/getMessagesOf/' + chatUid);
    
    // if (!loading) {
    //     username = data.username;
    //     pfpUrl = data.pfpUrl;
    //     messages = data.messages;
    // }

    function loadMessages() {
        axios({
            method: 'get',
            url: 'https://grandstaff.herokuapp.com/api/messages/getMessagesOf/' + chatUid,
            headers: {'Authorization': 'Bearer ' + (loggedUser != null ? loggedUser.token : '0')}
        }).then(res => {
            setUsername(res.data.username);
            setPfpUrl(res.data.pfpUrl);
            setMessages(res.data.messages);
        }).catch(err => {

        });
    }

    useEffect(() => {
        const socket = openSocket('https://grandstaff.herokuapp.com');
        socket.on('message', socketData => {
            if (socketData.userId === chatUid && socketData.userId2 === loggedUser.userId) {
                // loadMessages();
                setMessages(prev => [
                    ...prev,
                    socketData.message
                ]);
            }
            else {}
        });
        loadMessages();
    }, [ chatUid ]);

    useEffect(() => {
        // When the component mounts or messages are updated, scroll to the bottom of the stack
        stackRef.current.scrollTop = stackRef.current.scrollHeight;
      }, [messages]);

    const sendMessage = () => {
        if (chatUid) {
            axios({
                method: 'put',
                url: 'https://grandstaff.herokuapp.com/api/messages/sendMessage',
                headers: {'Authorization': 'Bearer ' + (loggedUser != null ? loggedUser.token : '0')},
                data: {
                    toId: chatUid,
                    message: chatInput
                }
            }).then(res => {
                setChatInput('');
                // loadMessages();
                setMessages(prev => [
                    ...prev,
                    {
                        content: res.data.msg,
                        timestamp: res.data.timestamp,
                        sender: ''
                    }
                ]);
            }).catch(err => {

            });
        }
        setChatInput('');
    };

    return (
        <Stack sx={{ width: "675px", height: '100%', direction: "column" }}>
            <Stack
                direction='row'
                flexShrink={0}
                paddingLeft="25px"
                alignItems="center"
                height="65px" style={{
                    borderBottom: '2px solid rgba(255, 255, 255, 0.2)',
                    borderImage: "linear-gradient(to right, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.2) 78%, rgba(255,255,255,0) 100%) 2"
                }}>
                <Box style={{
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundImage: `url(${pfpUrl})`,
                    borderRadius: '20px',
                    height: '35px',
                    width: '35px',
                    marginRight: '12px',
                    flexShrink: 0
                }} >
                </Box>
                <Typography variant="p" component="div" fontSize="20px">
                    <b>{username}</b>
                </Typography>
            </Stack>
            <Stack ref={stackRef} sx={{ direction: 'column', width: {lg: "675px", xs: "100%"}, overflowY: 'auto', flexGrow: 1 }}>
                {messages[0] ? messages.map(msg => <Message {...msg} />) : "" }
            </Stack>
            <Box sx={{ margin: "0 15px 15px 15px", }}>
                <TextField
                    label="Съобщение"
                    value={chatInput}
                    onChange={(e) => setChatInput(e.target.value)}
                    style={{ width: '100%' }}
                    multiline
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            if (!event.shiftKey) {
                                event.preventDefault();
                                if (chatInput.trim() !== '') {
                                    sendMessage();
                                }
                            }
                        }
                    }}
                />
            </Box>
        </Stack>
    );
}

export default ChatArea;