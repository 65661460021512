import { Box, useTheme } from "@mui/material";
import {
    useHMSStore,
    selectIsConnectedToRoom,
} from '@100mslive/react-sdk';
import Join from '../components/concerts/Join';
import Room from '../components/concerts/Room';

const Concerts = () => {
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';

    const isConnected = useHMSStore(selectIsConnectedToRoom);
    return (
        <Box sx={{ overflowY: 'auto', borderRadius: "15px", width: "100%", backgroundColor: "background.default", margin: {lg: "15px 15px 15px 0", xs: "10px 0 0 0"} }}>
            <Box sx={{ height: '100%', scrollSnapAlign: 'start', backgroundSize: 'cover', backgroundPosition: 'right bottom', backgroundImage: `url(${"https://cdn.discordapp.com/attachments/802857269796667422/1074727832510533713/wp5310213-concert-hall-wallpapers.png"})` }}>
            <Box sx={{ padding: { xl: "50px 75px 0 75px", lg: "15px" }, height: '100%', backgroundSize: "100% 100%", backgroundImage: `url(${isDark ? "https://grandstaff.herokuapp.com/images/DarkFade.png" : "https://grandstaff.herokuapp.com/images/LightFade.png"})` }}>
                    {
                        isConnected ? <Room /> : <Join />
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default Concerts;