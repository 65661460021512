import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ListingFilters from "../components/ListingFilters";
import Listings from "../components/Listings";
// import useFetch from "../hooks/useFetch";
import { listingData } from "../lib/listingData";
import CenteredSpinner from "../components/CenteredSpinner";
import axios from "axios";

const Find = ({ createMode }) => {
    let { search, pathname } = useLocation();
    const user = JSON.parse(localStorage.getItem('user'));
    let { _id } = useParams();
    let params = _id;
    const navigate = useNavigate();
    if (params===undefined && user===null && pathname.includes('/job')) navigate('/signup');
    if (pathname.includes("/job") || search.includes("type=Employer")) {
        if (pathname.includes('/job') && user) search = ('?type=Employer&owner=' + user.userId);
    }
    //console.log(search);
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    function loadListings() {
        axios({
            method: 'get',
            url: 'https://grandstaff.herokuapp.com/api/find' + (search=="" ? "?type=Musician" : search),
            headers: {'Authorization': 'Bearer ' + (user != null ? user.token : '0')}
        }).then(res => {
            setData(res.data);
            setLoading(false);
        }).catch(err => {

        });
    }

    useEffect(() => {
        loadListings();
        setLoading(true);
    }, [ search, pathname, _id ]);

    //Kato e na createMode da se dobavi v search querito user idto i type=employers direktno v koda
    // const { data, loading } = useFetch('https://grandstaff.herokuapp.com/api/find' + (search=="" ? "?type=Musician" : search));
    //console.log("BOB", data);

    return (
        <Box sx={{ overflowY: 'auto', borderRadius: "15px", padding: "15px 25px", width: "100%", backgroundColor: "background.default", margin: {lg: "15px 15px 15px 0", xs: "10px 0 0 0"} }}>
            {loading ? 
                <CenteredSpinner /> :
                <Listings listingsOg={data.queryUsers ? data.queryUsers : data.queryBands} type={data.type} createMode={pathname === '/job'} search={search} />
            }
        </Box>
    );
}

export default Find;