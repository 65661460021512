import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { chipData } from '../lib/chipData';
import DialogPopup from './DialogPopup';
import { useNavigate } from 'react-router';
import BandTile from './BandTile';
import axios from 'axios';
import useFetch from '../hooks/useFetch';
import { alpha } from "@mui/system";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const AddToBand = (userId) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    let userItem = JSON.parse(localStorage.getItem('user'));
    if (!userItem) userItem = {userId: '0', token: '0'};
    const { data, loading, error } = useFetch('https://grandstaff.herokuapp.com/api/getUser/' + userItem.userId);
    let user;
    let bands;
    if (!loading) { 
        user = data.user; 
        bands = (user ? user.bands : []); 
        //console.log(data);
    }
    //console.log(bands);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const [dataM, setData] = React.useState([]);
    React.useEffect(() => {
        axios({
            method: 'post',
            url: 'https://grandstaff.herokuapp.com/api/band/getBands',
            data: {
                bandIds: bands
            }
        }).then(res => {
            setData([]);
            res.data.bands.forEach(band => {
                //console.log(band.members);
                //console.log("USERID", userId.userId);
                //console.log(band.members.includes(userId.userId));
                if (!band.members.includes(userId.userId)) setData(prev => [...prev, band]);
            });
        }).catch(err => {
            //console.log(err);
        }).finally(() => {
            //console.log(dataM);
        });
    }, [open]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const createBand = () => {
        navigate('/band/' + "bandID") //sig 6e trqq timeout predi redirecta da moje backenda da syzdade bandata
        handleClose();
    }


    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <GroupAddIcon fontSize="large" />
            </IconButton>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    sx: {
                        backdropFilter: 'blur(10px)',
                        backgroundColor: theme => alpha(theme.palette.background.paper, 0.67),
                        borderRadius: "16px"
                    },
                }}
            >
                <DialogTitle sx={{ display: 'flex' }}>
                    {"Добавяне към Група"}
                </DialogTitle>
                <Stack direction={'column'} width={isMobile ? "calc(100vw - 64px)" : "400px"}>
                    {dataM.map(el => {
                        return <BandTile {...el} addMode userId={userId} />
                    })}
                </Stack>
                <DialogActions>
                    <Button onClick={handleClose} color="error">Отказ</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddToBand;
