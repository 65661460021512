import { alpha, Box, Button, Chip, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import EditField from "./EditField";
import { useContext, useEffect, useState } from "react";
import DialogPopup from "./DialogPopup";
import { chipData } from "../lib/chipData";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PfpUpload from "./PfpUpload";
import EditRegionPopup from "./EditRegionPopup";
import EditAccount from "./EditAccount";
import EditBand from "./EditBand";
import userEvent from "@testing-library/user-event";
import openSocket from 'socket.io-client';
import { EntityContext } from "../context/EntityContext";
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { useNavigate } from "react-router";
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from "axios";
import BandCandidacyPopup from "./BandCandidacyPopup";

const UserDetails = () => {
    const { _id, firstName, lastName, pfpUrl, instruments, genres, city, editView, type, name, archived, owner } = useContext(EntityContext);
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const user = JSON.parse(localStorage.getItem('user'));
    const [ instr, setInstr ] = useState(instruments);

    const handleCandidacy = () => {
        axios({
            method: 'put',
            url: 'https://grandstaff.herokuapp.com/api/transaction/sendTransact/' + _id,
            headers: { 'Authorization': 'Bearer ' + (user ? user.token : '0') }
        }).then(res => {
            //console.log("TRANSACTION", res.data.transaction);
        }).catch(err => {
            //console.log('TRANSACTIONERR', err);
        });
    }

    useEffect(() => {
        const socket = openSocket('https://grandstaff.herokuapp.com');
        socket.on('instrChanged', socketData => {
            if (_id) {
                if (socketData.userIdC === _id) {
                    setInstr(socketData.instr);
                }
            }
        });
    }, []);

    const bandCandidacyPopup = () => {
            return (
                <Stack style={{ borderRadius: "6px" }} >
                    <BandCandidacyPopup />
                </Stack>
            );
    };

    return (
        <Box maxWidth={'700px'} marginLeft={isMobile ? '15px' : '70px'} key={_id} style={{ dispaly: "flex", flexDirection: "column" }}>
            <Stack direction={'row'}>
                {
                    type === 'Employer'
                        ?
                        null
                        :
                        <Box style={{
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundImage: `url(${pfpUrl})`,
                            borderRadius: '20px',
                            height: isMobile ? '85px' : '125px',
                            width: isMobile ? '85px' : '125px',
                            marginRight: '15px',
                            flexShrink: 0
                        }} >
                            {
                                editView
                                    ?
                                    <PfpUpload /> //REWIRED
                                    :
                                    null
                            }
                        </Box>
                }
                <Stack direction={'column'} padding={'5px 0 5px 0'}>
                    <div style={{ display: 'flex' }}>
                        <Typography gutterBottom variant="h5" component="div">
                            {type === 'Band' ? name : (firstName + ' ' + lastName)}
                        </Typography>
                        {
                            editView && !archived
                                    ?
                                    ((type==='Band' || type==='Employer') ? 
                                    <Box marginTop="-5px">
                                        <EditBand/> 
                                    </Box>
                                    :
                                    <Box marginTop="-5px">
                                        <EditAccount />
                                    </Box>) : null
                        }
                        {
                            editView
                                ?
                                <Box width='10px' />
                                :
                                <Box width='15px' />
                        }
                        {!isMobile ? <> {/* Location and messages for Desktop */}
                        {
                            editView && !archived
                                ?
                                <EditRegionPopup data={chipData[0]} /> //REWIRED
                                :
                                <Chip icon={<LocationOnIcon fontSize='small' />} label={city === 'Undefined' ? 'Непосочена' : city} />
                        }
                        {
                            type === "Musician" && !editView
                                ?
                                <Box width='10px' />
                                :
                                null
                        }
                        {
                            type === "Musician" && !editView && user 
                                ?
                                <Chip style={{ borderRadius: "6px" }} icon={<InsertCommentIcon fontSize='small' />} label="Съобщение" onClick={() => navigate('/messages/' + _id)}/>
                                :
                                null
                        }
                        {
                            type === 'Employer' && user && owner!==user.userId  && !archived
                                ?
                                // <Chip style={{ borderRadius: "6px" }} icon={<NotificationsIcon />} label="Кандидатствай" onClick={handleCandidacy} />
                                bandCandidacyPopup()
                                :
                                null
                        }
                        </> : null}
                    </div>
                    {isMobile ? <> {/* Location and messages for Mobile */}
                        <Stack direction="row" paddingBottom="5px">
                            {
                                editView && !archived
                                    ?
                                    <EditRegionPopup data={chipData[0]} /> //REWIRED
                                    :
                                    <Chip icon={<LocationOnIcon fontSize='small' />} label={city === 'Undefined' ? 'Непосочена' : city} />
                            }
                            {
                                type === "Musician" && !editView
                                    ?
                                    <Box width='10px' />
                                    :
                                    null
                            }
                            {
                                type === "Musician" && !editView && user
                                    ?
                                    <Chip style={{ borderRadius: "6px" }} icon={<InsertCommentIcon fontSize='small' />} label="Съобщение" onClick={() => navigate('/messages/' + _id)}/>
                                    :
                                    null
                            }
                            {
                                type === 'Employer' && user && owner!==user.userId && !archived
                                    ?
                                    // <Chip style={{ borderRadius: "6px" }} icon={<NotificationsIcon />} label="Кандидатствай" onClick={handleCandidacy} />
                                    bandCandidacyPopup()
                                    :
                                    null
                            }
                        </Stack>
                    </> : null}
                    {!isMobile ? <> {/* Instruments and genres for Desktop */}
                    {
                        editView && !archived
                            ?
                            <DialogPopup data={chipData[1]} selected={instr} /> //REWIRED
                            :
                            <Stack direction='row' spacing={'2px'}>
                                {instr[0] ? instr.map(instr => <Chip key={instr} label={instr} />) : ""}
                            </Stack>
                    }
                    <Box sx={{ height: '5px' }} />
                    <Stack direction='row' spacing={'2px'}>
                        {
                            editView && !archived
                                ?
                                <DialogPopup data={chipData[2]} selected={genres} /> //REWIRED
                                :
                                <Stack direction='row' spacing={'2px'}>
                                    {genres.map(genre => <Chip key={genre} label={genre} />)}
                                </Stack>
                        }
                    </Stack>
                    </> : null}
                </Stack>
            </Stack>
            {isMobile ? <> {/* Instruments and genres for Mobile */}
                {
                    editView && !archived
                        ?
                        <Stack gap={'2px'}>
                            <DialogPopup data={chipData[1]} selected={instr} />
                            <DialogPopup data={chipData[2]} selected={genres} />
                        </Stack>
                        :
                        <Stack direction='row' gap={'2px'} flexWrap="wrap">
                            {instr.map(instr => <Chip key={instr} label={instr} />)}
                            <Box sx={{ width: '2px' }} />
                            <Box sx={{ width: '1px', height: '32px', backgroundColor: '#ffffffDD' }} />
                            <Box sx={{ width: '2px' }} />
                            {genres.map(genre => <Chip  key={genre} label={genre} />)}
                        </Stack>
                }
            </> : null}
        </Box>
    );
}

export default UserDetails;