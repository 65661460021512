import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FacebookIcon from '@mui/icons-material/Facebook';
import axios from 'axios';
import { useLogin } from '../hooks/useLogin';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

export default function Login() {
    const { login, error, isLoading } = useLogin();
    const navigate = useNavigate();

    const stringifiedParams = queryString.stringify({
        client_id: '553165626933948',
        redirect_uri: 'https://www.grandstaff.online/facebook/',
        scope: ['email', 'public_profile'].join(','), // comma seperated string
        response_type: 'code',
        auth_type: 'rerequest',
        display: 'popup',
    });
      
    const facebookLoginUrl = `https://www.facebook.com/v4.0/dialog/oauth?${stringifiedParams}`;

    const handleSubmit = async (event) => { //async await is unnecessary here?!
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        await login(data.get('email'), data.get('password'));
        if (localStorage.getItem('user')) navigate('/');
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Впиши се
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Имейл"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Парола"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={isLoading}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Вписване
                    </Button>
                    <Grid container spacing={4}>
                        <Grid item>
                            <Link href="/signup" variant="body2">
                                {"Нямате акаунт? Регистрирайте се!"}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/forgotpass" variant="body2">
                                {"Забравена парола?"}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography color={"red"}>
                                { error }
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <a href={facebookLoginUrl}><Button
                                variant="contained"
                                startIcon={<FacebookIcon />}
                                sx={{color: "#FFF", backgroundColor: "#3482E2", [":hover"]:{backgroundColor: "#21538C"} }}
                                >
                                Влез с Facebook
                            </Button></a>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}