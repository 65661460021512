import { Box, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import ChatSideView from "../components/messages/ChatSideView";
import ChatArea from "../components/messages/ChatArea";
import { useTheme } from "@emotion/react";
import { useNavigate, useParams } from "react-router";
import MobileSideViewDrawer from "../components/messages/MobileSideViewDrawer";
import { useEffect } from "react";

const Messages = () => {
    let { chatUid } = useParams();
    let user = { background: "Тромпет" }; //Get background image from back end
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const loggedUser = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedUser === null) navigate('/login');
    }, [])

    return (
        <Box sx={{ width: "100%", backgroundImage: `url('https://grandstaff.herokuapp.com/images/inst/${user.background}.png')` }}>
            <Box sx={{ height: '100%', backgroundSize: "100% 100%", backgroundImage: `url(${isDark ? "https://grandstaff.herokuapp.com/images/DarkFade.png" : "https://grandstaff.herokuapp.com/images/LightFade.png"})` }}>
                <Stack width="100%" height="100%" direction={"row"}>
                    {isMobile ? <MobileSideViewDrawer /> : <ChatSideView />}
                    <ChatArea />
                </Stack>
            </Box>
        </Box>
    );
}

export default Messages;