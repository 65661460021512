export const chipData = [
    {
        chipName: 'Област',
        title: 'Области',
        queryParam: 'region',
        content: [
            'Благоевград',
            'Бургас',
            'Добрич',
            'Габрово',
            'Хасково',
            'Кърждали',
            'Кюстендил',
            'Ловеч',
            'Монтана',
            'Пазарджик',
            'Перник',
            'Плевен',
            'Пловдив',
            'Разград',
            'Русе',
            'Шумен',
            'Силистра',
            'Сливен',
            'Смолян',
            'София',
            'Стара Загора',
            'Търговище',
            'Варна',
            'Велико Търново',
            'Видин',
            'Враца',
            'Ямбол',
        ],
    },
    {
        chipName: 'Инструмент',
        title: 'Инструменти/вокалисти',
        queryParam: 'instrument',
        content: [
            "Барабани",
            "Перкусии",
            "Вибрафон",
            "Арфа",
            "Пиано",
            "Акордион",
            "Синтезатор",
            "Цигулка",
            "Виола",
            "Виолончело",
            "Контрабас",
            "Флейта",
            "Обои",
            "Кларинет",
            "Фагот",
            "Тромпет",
            "Флигорна",
            "Тромбон",
            "Валдхорна",
            "Баритон",
            "Туба",
            "Китара",
            "Баскитара",
            "Саксофон",
            "Гайда",
            "Гъдулка",
            "Кавал",
            "Тъпан",
            "Тамбура",
            "Зурна",
            "Бузуки",
            "Саз",
            "Уд",
            "Вокал",
        ],
    },
    {
        chipName: 'Жанр',
        title: 'Жанрове',
        queryParam: 'genre',
        content: [
            'Рок',
            'Метъл',
            'Блус',
            'Джаз',
            'Класическа',
            'Народна',
            'Поп',
            'Реге',
            'Латино',
            'Хип-хоп',
            'Фънк',
            'Поп-фолк',
	    'Диско'
        ],
    },
]

export const ChipDropdownData = [
    {
        chipName: 'Тип',
        queryParam: 'type',
        default: 'Musician',
        btns: [
            {
                param: 'Musician',
                name: 'Музиканти'
            },
            {
                param: 'Band',
                name: 'Групи'
            },
            {
                param: 'Employer',
                name: 'Обяви'
            },
        ]
    },
    {
        chipName: 'Образование',
        queryParam: 'uEdu',
        default: 'false',
        btns: [
            {
                param: 'false',
                name: 'Всички'
            },
            {
                param: 'true',
                name: 'Висшисти'
            },
        ]
    }
]